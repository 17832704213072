import React from 'react'
import ImageGallery from 'react-image-gallery';

// # SCSS
// @import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

// # CSS
// import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

// # Webpack
import "react-image-gallery/styles/css/image-gallery.css";

// # Stylesheet with no icons
// node_modules/react-image-gallery/styles/scss/image-gallery-no-icon.scss
// node_modules/react-image-gallery/styles/css/image-gallery-no-icon.css

import campus01 from '../pics/campus/barcelona_code_school_campus_.jpg'
import campus02 from '../pics/campus/barcelona_code_school_campus_-2.jpg'
import campus03 from '../pics/campus/barcelona_code_school_campus_-3.jpg'
import campus04 from '../pics/campus/barcelona_code_school_campus_-4.jpg'
import campus05 from '../pics/campus/barcelona_code_school_campus_-6.jpg'
import campus06 from '../pics/campus/barcelona_code_school_campus_-9.jpg'

class Campus extends React.Component {

	constructor() {
		super()
		this.state = {
			showIndex: false,
			slideOnThumbnailHover: false,
			showBullets: false,
			infinite: true,
			showThumbnails: false,
			showFullscreenButton: false,
			showGalleryFullscreenButton: true,
			showPlayButton: false,
			showGalleryPlayButton: true,
			showNav: false,
			slideDuration: 450,
			slideInterval: 2000,
			thumbnailPosition: 'bottom',
			showVideo: {},
		}}

		render() {

			const images = [
			{
				original: campus01
			},
			{
				original: campus02
			},
			{
				original: campus03
			},
			{
				original: campus04
			},
			{
				original: campus05
			},
			{
				original: campus06
			},
			]

			return (
				<div>
				<ImageGallery items={images} 
				disableArrowKeys={false}
				autoPlay={true}
				lazyLoad={false}
				infinite={this.state.infinite}
				showBullets={this.state.showBullets}
				showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
				showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
				showThumbnails={this.state.showThumbnails}
				showIndex={this.state.showIndex}
				showNav={this.state.showNav}
				thumbnailPosition={this.state.thumbnailPosition}
				slideDuration={parseInt(this.state.slideDuration)}
				slideInterval={parseInt(this.state.slideInterval)}
				slideOnThumbnailHover={this.state.slideOnThumbnailHover}
				additionalClass="app-image-gallery"
				style={{width: '40vw'}}
				/>
				</div>
				);
		}

	}

	export default Campus